/* Home.css */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #333;
}

body {
  background-color: #1b1b1b;
  color: #ffffff;
}

header {
  background-color: var(--header-bg-color);
}

.events_headliner {
  text-align: center;
}

.text-boxes-container {
  display: flex;
  justify-content: flex-start; /* Aligns the text boxes to the left */
  flex-wrap: wrap; /* Ensures text boxes can wrap onto the next line */
  /*gap: 0px; /* Adds some space between the text boxes */
  justify-content: flex-start; /* Aligns text boxes to the start of the container */
  /* margin-bottom: 10px; Adds some space above the filter buttons and table */
}

.box-vertical {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  /*margin-bottom: 1px; /* Add space below each group if needed */
}

.text-box {
  border-radius: 10px; /* Rounded corners */
  border: 1px solid ;  /*Light border for the text boxes */
  padding: 10px; /* Padding inside the text boxes */
  width: 250px; /* Sets a standard width for all text boxes */
  font-weight: bold;
}

.box1 {
  background-color: #62d3c0;
  color: black;
}
.box2 {
  background-color: #b391cf;
  color: black;
}
.box3 {
  background-color: #79bce9;
  color: black;
}
.box4 {
  background-color: #ddcc92;
  color: black;
}

.time-since-last-event {
  color: rgb(231, 88, 88);
  text-align: center;
}

/* Responsive media query */
@media (max-width: 768px) {
  .text-boxes-container {
    flex-direction: column; /* Stacks text boxes vertically */
  }
  .text-box {
    width: 250px;; /* Makes text boxes full width on smaller screens */
  }
}

.box-vertical-text {
  color: rgb(231, 88, 88);
}

.filter-button {
  /*padding: 10px 20px;*/
  margin-right: 5px;
  margin-left: 5px;
  border: none;
  border-radius: 5px;
  background-color: #2f4350;
  color: white;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  margin-bottom: 5px;
  min-width: 50px;
}

.filter-button:hover {
  background-color: #456174;
}

.filter-button.active {
  background-color: #427288;
}
