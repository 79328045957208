.app-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  /*align-items: center;  Center vertically */
  /*min-height: 100vh; /* Make sure it takes at least the full height of the viewport */
}
/*
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
*/
