/* Scoreboard.css */
.regler-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  /*align-items: center;  Center vertically */
  min-height: 100vh; /* Make sure it takes at least the full height of the viewport */
  max-width: 900px;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.button-17 {
  margin: 5px;
  padding: 10px 20px;
  background-color: rgb(170, 170, 170);
  color: rgb(53, 53, 53);
  border: none;
  cursor: pointer;
  font-weight: bold;
}

@media (max-width: 600px) {
  .button-container {
    flex-direction: column;
  }

  .button-17 {
    width: 100%;
    margin: 5px 0;
  }
}
