/*
.images-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .uploaded-images-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    margin-top: 20px;
  }
  
  .thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .thumbnail:hover {
    transform: scale(1.1);
  }
  
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    z-index: 1000;
  }
  
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .modal-content {
    max-width: 90%;
    max-height: 90%;
  }
  
  .caption {
    margin-top: 10px;
    color: #ccc;
    font-size: 20px;
  }
  
  .image-container {
    position: relative;
  }
  
  .delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: red;
    color: white;
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .delete-button:hover {
    background-color: darkred;
  }
  
  .bilder_top_text {
    text-align: center;
  }
  */
  .bilder_top_text {
    text-align: center;
  }
  
  .table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .images-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .uploaded-images-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  
  .image-container {
    position: relative;
    width: 150px;
    height: 150px;
  }
  
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .delete-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 2rem;
    text-align: center;
    line-height: 135px; /* Vertically center the cross */
    cursor: pointer;
    opacity: 0.8;
  }
  
  .delete-button:hover {
    opacity: 1;
  }
  
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .modal-content {
    max-width: 90%;
    max-height: 90%;
  }
  
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
  
  .caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }
  
  .rating-input {
    width: 60px;
    text-align: center;
  }
  